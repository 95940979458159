import Vue from "vue";

import DirectoryBreadcrumbs from "./DirectoryBreadcrumbs.vue";
import JobProgress from "./JobProgress.vue";
import ListEditor from "./ListEditor.vue";
import NotificationDisplay from "./NotificationDisplay.vue";
import PanelLink from "./PanelLink.vue";
import StatusLabel from "./StatusLabel.vue";
import ThumbnailImg from "./ThumbnailImg.vue";

Vue.component("directory-breadcrumbs", DirectoryBreadcrumbs);
Vue.component("job-progress", JobProgress);
Vue.component("list-editor", ListEditor);
Vue.component("notification-display", NotificationDisplay);
Vue.component("panel-link", PanelLink);
Vue.component("status-label", StatusLabel);
Vue.component("thumbnail-img", ThumbnailImg);
