<template>
	<div class="text-xs-center mt-2">
		<h1>
			404 page not found
		</h1>
	</div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({});
</script>