<template>
	<v-row>
		<v-col>
			<job-file-list />
		</v-col>
	</v-row>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({});
</script>
