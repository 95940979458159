import { render, staticRenderFns } from "./FileTransferDialog.vue?vue&type=template&id=173aef26&scoped=true"
import script from "./FileTransferDialog.vue?vue&type=script&lang=ts"
export * from "./FileTransferDialog.vue?vue&type=script&lang=ts"
import style0 from "./FileTransferDialog.vue?vue&type=style&index=0&id=173aef26&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "173aef26",
  null
  
)

export default component.exports