<template>
	<v-row :dense="$vuetify.breakpoint.mobile">
		<v-col>
			<webcam-panel />
		</v-col>
	</v-row>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({});
</script>
