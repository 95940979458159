import { render, staticRenderFns } from "./WebcamPanel.vue?vue&type=template&id=46ea8afe&scoped=true"
import script from "./WebcamPanel.vue?vue&type=script&lang=ts"
export * from "./WebcamPanel.vue?vue&type=script&lang=ts"
import style0 from "./WebcamPanel.vue?vue&type=style&index=0&id=46ea8afe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46ea8afe",
  null
  
)

export default component.exports