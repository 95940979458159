import { render, staticRenderFns } from "./ExtraSensorList.vue?vue&type=template&id=bddda45a&scoped=true"
import script from "./ExtraSensorList.vue?vue&type=script&setup=true&lang=ts"
export * from "./ExtraSensorList.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ExtraSensorList.vue?vue&type=style&index=0&id=bddda45a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bddda45a",
  null
  
)

export default component.exports